
import logo from './logo.svg';
import React from 'react';

import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import NavBar from './NavBar';

import './App.css';





class App extends React.Component{
    handleClickScroll = () => {
        const element = document.getElementById('contact');
        if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' });
        }
        
    };

  WhatsappLink(text){
    return (`https://wa.me/85263373578?text=${encodeURIComponent(text)}`);
  }

  render(){
  return (
  <div className="App">
       <nav id="navbarExample" className="navbar navbar-expand-lg fixed-top navbar-light" aria-label="Main navigation">
            <div className="container">

                 <NavBar/>
              
                 <a className="navbar-brand logo-text" href="https://lbp.com.hk/">LBP 科昌科技有限公司</a> 

                <button className="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
                    <ul className="navbar-nav ms-auto navbar-nav-scroll">
                        {/* <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="#header">Home</a>
                        </li> */}
                        {/* <li className="nav-item">
                            <a className="nav-link" href="#services">Services</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#details">Details</a>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link" href={this.WhatsappLink("我想了解更多關於智慧Pos方案")}>智慧Pos方案</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false">5大餐廳類型</a>
                            <ul className="dropdown-menu" aria-labelledby="dropdown01">
                                <li><a className="dropdown-item" href={this.WhatsappLink("我想了解更多關於咖啡店/飲品店/快餐店")}>咖啡店/飲品店/快餐店</a></li>
                                <li><a className="dropdown-item" href={this.WhatsappLink("我想了解更多關於茶餐廳/冰室/西餐/酒楼")}>茶餐廳/冰室/西餐/酒楼</a></li>
                                <li><a className="dropdown-item" href={this.WhatsappLink("我想了解更多關於沙律/米線/DIY餐牌")}>沙律/米線/DIY餐牌</a></li>
                                <li><a className="dropdown-item" href={this.WhatsappLink("我想了解更多關於Foodcourt 美食廣場")}>Foodcourt 美食廣場</a></li>
                                <li><a className="dropdown-item" href={this.WhatsappLink("我想了解更多關於中央廚房/虛擬餐廳")}>中央廚房/虛擬餐廳</a></li>
                            </ul>
                        </li>
                    </ul>
                    <span className="nav-item">
                        {/* <a className="btn-solid-sm" href="/login">登入 | 餐廳控制台</a> */}
                        <Link to="/login">登入 | 餐廳控制台</Link>
                    </span>
                    <span className="nav-item">
                        <a className="btn-solid-sm" href="#contact" onClick={this.handleClickScroll}>免費獲取Demo</a>
                    </span>
                </div> 
            </div> 
        </nav>
      
        <header id="header" className="header">
            {/* <div className="container">
                <div className="row"> */}
{/*                     <div className="col-lg-6 col-xl-5">
                        <div className="text-container">
                            <h1 className="h1-large">Find influencers for your products</h1>
                            <p className="p-large">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dignissim, neque ut ultrices sollicitudin</p>
                            <a className="btn-solid-lg" href="#services">Offered services</a>
                        </div>
                    </div> 
                    <div className="col-lg-6 col-xl-7">
                        <div className="image-container">
                            <img className="img-fluid" src="assets/images/header-image.png" alt="alternative" />
                        </div> 
                    </div>  */}
    <MDBCarousel showIndicators showControls fade>
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={1}
        src='assets/images/casher.jpg'
        alt='...'
      >
        {/* <h5>讓</h5> */}
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
      </MDBCarouselItem>

      <MDBCarouselItem
        className='w-100 d-block'
        itemId={2}
        src='assets/images/smarterPOS.jpg'
        alt='...'
      >
        {/* <h5>Third slide label</h5> */}
        {/* <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
      </MDBCarouselItem>
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={3}
        src='assets/images/waiters-interface.jpg'
        alt='...'
      >
        {/* <h5>Third slide label</h5> */}
        {/* <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
      </MDBCarouselItem>
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={4}
        src='assets/images/all_department.webp'
        alt='...'
      >
        {/* <h5>Third slide label</h5> */}
        {/* <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
      </MDBCarouselItem>
    </MDBCarousel>
        </header> 
      
        <div id="services" className="cards-1 bg-gray">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2>30分鐘建立屬於你的雲端智能餐廳</h2>
                    </div> 
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        
                      
                        <div className="card">
                            <img className="img-fluid" src="assets/images/c974d399ba9ee1ded54c554459f967c4.png" alt="alternative" />
                            <div className="card-body">
                                <h5 className="card-title">咖啡店/飲品店/快餐店</h5>
                                <li>電子菜單、掃碼落單</li>
                                <li>支持會員、印花、積分</li>
                                <li>堂食、自取、外送</li>
                                {/* <p>Et blandit nisl libero at arcu. Donec ac lectus sed tellus mollis viverra. Nullam pharetra ante at nunc elementum</p> */}
                                <a className="read-more no-line" href={this.WhatsappLink("我想了解更多關於咖啡店/飲品店/快餐店")}>了解更多<span className="fas fa-long-arrow-alt-right"></span></a>
                            </div>
                        </div>
                        <div className="card">
                            <img className="img-fluid" src="assets/images/15eeabae24128b71847db9aedfeaa5d4.png" alt="alternative" />
                            <div className="card-body">
                                <h5 className="card-title">茶餐廳/冰室/西餐/酒樓</h5>
                                {/* <p>Et blandit nisl libero at arcu. Donec ac lectus sed tellus mollis viverra. Nullam pharetra ante at nunc elementum</p> */}
                                <li>100%支持複雜餐牌</li>
                                <li>100%貼合餐廳營運習慣</li>
                                <li>支持會員、印花、積分</li>
                                <li>堂食、自取、外送</li>
                                <a className="read-more no-line" href={this.WhatsappLink("我想了解更多關於茶餐廳/冰室/西餐/酒樓")}>了解更多<span className="fas fa-long-arrow-alt-right"></span></a>
                            </div>
                        </div>
                        <div className="card">
                            <img className="img-fluid" src="assets/images/8857c863cd75411b52127118882f957f.png" alt="alternative" />
                            <div className="card-body">
                                <h5 className="card-title">沙律/米線/DIY餐牌</h5>
                                {/* <p>Et blandit nisl libero at arcu. Donec ac lectus sed tellus mollis viverra. Nullam pharetra ante at nunc elementum</p> */}
                                <li>獨家支持客户DIY餐牌</li>
                                <li>米線、沙律等配料自由選</li>
                                <li>支持會員、印花、積分</li>
                                <li>堂食、自取、外送</li>
                                <a className="read-more no-line" href={this.WhatsappLink("我想了解更多關於沙律/米線/DIY餐牌")}>了解更多<span className="fas fa-long-arrow-alt-right"></span></a>
                            </div>
                        </div>
                        <div className="card">
                            <img className="img-fluid" src="assets/images/17edb08f6b9440da5b8308835db416e0.png" alt="alternative" />
                            <div className="card-body">
                                <h5 className="card-title">Foodcourt 美食廣場</h5>
                                {/* <p>Et blandit nisl libero at arcu. Donec ac lectus sed tellus mollis viverra. Nullam pharetra ante at nunc elementum</p> */}
                                <li>多檔口電子菜單</li>
                                <li>掃碼落單、支持外帶、外送</li>
                                <li>分檔口數據統計</li>
                                <a className="read-more no-line" href={this.WhatsappLink("我想了解更多關於Foodcourt 美食廣場")}>了解更多<span className="fas fa-long-arrow-alt-right"></span></a>
                            </div>
                        </div>
                        <div className="card">
                            <img className="img-fluid" src="assets/images/d169e8622fdaa9f95c5155e67859bb04.png" alt="alternative" />
                            <div className="card-body">
                                <h5 className="card-title">中央廚房/虛擬餐廳</h5>
                                {/* <p>Et blandit nisl libero at arcu. Donec ac lectus sed tellus mollis viverra. Nullam pharetra ante at nunc elementum</p> */}
                                <li>遠程落單、零租金成本</li>
                                <li>24小時接單</li>
                                <li>20多種營銷模式</li>
                                <li>堂食、自取、外送</li>
                                <a className="read-more no-line" href={this.WhatsappLink("我想了解更多關於中央廚房/虛擬餐廳")}>了解更多<span className="fas fa-long-arrow-alt-right"></span></a>
                            </div>
                        </div>
                        <div className="card">
                            <img className="img-fluid" src="assets/images/smartPOS.png" alt="alternative" />
                            <div className="card-body">
                                <h5 className="card-title">獲取您的智慧餐廳Pos演示</h5>
                                {/* <p>Et blandit nisl libero at arcu. Donec ac lectus sed tellus mollis viverra. Nullam pharetra ante at nunc elementum</p> */}

                                <a className="read-more no-line" href={this.WhatsappLink("我想獲取智慧餐廳Pos演示")}>了解更多<span className="fas fa-long-arrow-alt-right"></span></a>
                            </div>
                        </div>    

                    </div>
                </div>
            </div>
        </div> 

        <div id="details1" className="basic-2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-xl-5">
                        <div className="text-container">
                            {/* <div className="section-title"></div> */}
                            <h2>領先行業的電子菜單</h2>
                            {/* <p>Etiam tempus condimentum congue. In sit amet nisi eget massa condimentum lobortis eget ac eros. In hac habitasse platea dictumst. Aenean molestie mauris eget sapien sagittis, a bibendum magna tincidunt</p> */}
                            <li>支持多達100多種的菜品模式</li>
                            <li>支持茶餐廳、飲品店、西餐套餐、火鍋</li>
                            <li>支持堂食、外賣自提、外賣配送、驛站取餐</li>
                            <li>簡單、順暢的手機點餐體驗</li>
                            <a className="btn-outline-reg" href={this.WhatsappLink("我想了解更多關於電子菜單")}>了解更多</a>
                        </div> 
                    </div> 
                    <div className="col-lg-6 col-xl-7">
                        <div className="image-container">
                            {/* <img className="img-fluid" src="assets/images/details-2.png" alt="alternative" /> */}
                            <img className="img-fluid" src="assets/images/160640308467320201126230444.jpg" alt="alternative" />
                        </div> 
                    </div> 
                </div>
            </div> 
        </div> 
       
        <div id="details2" className="basic-1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-xl-7">
                        <div className="image-container">
                            <img className="img-fluid" src="assets/images/160640273710620201126225857.jpg" alt="alternative" />
                        </div> 
                    </div> 
                    <div className="col-lg-6 col-xl-5">
                        <div className="text-container">
                            {/* <div className="section-title">WHAT WE DO</div> */}
                            <h2>餐廳數據一手掌握</h2>
                            {/* <p>Etiam tempus condimentum congue. In sit amet nisi eget massa condimentum lobortis eget ac eros. In hac habitasse platea dictumst. Aenean molestie mauris eget sapien sagittis, a bibendum magna tincidunt</p> */}
                            <li>餐廳營運數據實時展現</li>
                            <li>訂單、交易額、會員</li>
                            <li>財務數據統計、分析</li>
                            <li>全店智能數據分析，發現趨勢</li>
                            <a className="btn-outline-reg" href={this.WhatsappLink("我想了解更多關於餐廳數據分析")}>了解更多</a>
                        </div> 
                    </div> 
                </div>
            </div> 
        </div>

        <div id="details3" className="basic-2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-xl-5">
                        <div className="text-container">
                            {/* <div className="section-title"></div> */}
                            <h2>建立會員計劃，幫你找到VIP</h2>
                            {/* <p>Etiam tempus condimentum congue. In sit amet nisi eget massa condimentum lobortis eget ac eros. In hac habitasse platea dictumst. Aenean molestie mauris eget sapien sagittis, a bibendum magna tincidunt</p> */}
                            <li>設置會員計劃、電子印花</li>
                            <li>會員消費智能分析，精準定位核心會員</li>
                            <li>篩選會員進行精準優惠券推送</li>
                            <a className="btn-outline-reg" href={this.WhatsappLink("我想了解更多關於餐廳會員計劃")}>了解更多</a>
                        </div> 
                    </div> 
                    <div className="col-lg-6 col-xl-7">
                        <div className="image-container">
                            {/* <img className="img-fluid" src="assets/images/details-2.png" alt="alternative" /> */}
                            <img className="img-fluid" src="assets/images/membership.png" alt="alternative" />
                        </div> 
                    </div> 
                </div>
            </div> 
        </div> 

        <div id="details4" className="basic-2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-xl-7">
                        <div className="image-container">
                            <img className="img-fluid" src="assets/images/all_department.webp" alt="alternative" />
                        </div> 
                    </div> 
                    <div className="col-lg-6 col-xl-5">
                        <div className="text-container">
                            {/* <div className="section-title">WHAT WE DO</div> */}
                            <h2>餐廳管理，隨手搞掂</h2>
                            {/* <p>Etiam tempus condimentum congue. In sit amet nisi eget massa condimentum lobortis eget ac eros. In hac habitasse platea dictumst. Aenean molestie mauris eget sapien sagittis, a bibendum magna tincidunt</p> */}
                            <li>手機、pad上輕鬆完成菜品上下架、庫存管理</li>
                            <li>訂單、交易額、會員</li>
                            <li>配合財務製作日結單、財務數據對賬</li>
                            <li>豐富支付方式可選</li>
                            <a className="btn-outline-reg" href={this.WhatsappLink("我想了解更多關於餐廳管理")}>了解更多</a>
                        </div> 
                    </div> 
                </div>
            </div> 
        </div>
        
        <div id="services" className="cards-1 bg-gray">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2>我們如何幫助餐廳「成功」</h2>
                    </div> 
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        
                      
                    <div className="card">
                            <div className="card-icon">
                                {/* <span className="fas fa-headphones-alt"></span> */}
                                {/* <img src="https://www.pin2eat.com/img/service1.6ef7743a.svg"></img> */}
                                <img className="img-fluid" src="/assets/images/consultants.svg" alt="alternative" />
                            </div>
                            
                            <div className="card-body">
                                <h5 className="card-title">專人餐廳 營運顧問</h5>
                                <p>提供7x16小時
                                專人支持，協助解決營運問題</p>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-icon">
                                {/* <span className="fas fa-headphones-alt"></span> */}
                                {/* <img src="https://www.pin2eat.com/img/service1.6ef7743a.svg"></img> */}
                                <img className="img-fluid" src="/assets/images/schedule.svg" alt="alternative" />
                            </div>
                            
                            <div className="card-body">
                                <h5 className="card-title">7x24小時 系統安全管理</h5>
                                <p>多重數據安全防護 </p> <p>提供穩定高效的雲端服務</p>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-icon">
                                {/* <span className="fas fa-headphones-alt"></span> */}
                                {/* <img src="https://www.pin2eat.com/img/service1.6ef7743a.svg"></img> */}
                                <img className="img-fluid" src="/assets/images/book.svg" alt="alternative" />
                            </div>
                            
                            <div className="card-body">
                                <h5 className="card-title">免費餐廳電子 營運課程</h5>
                                <p>資深餐飲專家，推出培訓課程
指導餐廳營運海報設計，優惠券、印花設置等日常營運
協助快速升級「雲端智能餐廳」，並迅速提升營收</p>
                                {/* <a className="read-more no-line" href="#">Learn more <span className="fas fa-long-arrow-alt-right"></span></a> */}
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div> 

        {/* <div className="cards-2 bg-gray">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1 className="h1-heading">全港超過600+餐廳正在使用</h1>
                        <h2 className="h2-heading">成功處理100,000單/日</h2>
                    </div> 
                </div>
                <div className="row">
                    <div className="col-lg-12">

                        
                        <div className="card">
                            <img className="quotes" src="assets/images/quotes.svg" alt="alternative" />
                            <div className="card-body">
                                <p className="testimonial-text">QR Code落單，令到客人方便落單，營運上更順暢。</p>
                                <div className="testimonial-author">春禧堂</div>
                                <div className="occupation">General Manager, Presentop</div>
                            </div>
                            <div className="gradient-floor red-to-blue"></div>
                        </div>
                      

                        
                        <div className="card">
                            <img className="quotes" src="assets/images/quotes.svg" alt="alternative" />
                            <div className="card-body">
                                <p className="testimonial-text">Suspendisse vitae enim arcu. Aliqu convallis risus a felis blandit, at mollis nisi bibendum aliquam noto ricos</p>
                                <div className="testimonial-author">Sam Bloom</div>
                                <div className="occupation">General Manager, Presentop</div>
                            </div>
                            <div className="gradient-floor blue-to-purple"></div>
                        </div>
                      

                        
                        <div className="card">
                            <img className="quotes" src="assets/images/quotes.svg" alt="alternative" />
                            <div className="card-body">
                                <p className="testimonial-text">Suspendisse vitae enim arcu. Aliqu convallis risus a felis blandit, at mollis nisi bibendum aliquam noto ricos</p>
                                <div className="testimonial-author">Bill McKenzie</div>
                                <div className="occupation">General Manager, Presentop</div>
                            </div>
                            <div className="gradient-floor purple-to-green"></div>
                        </div>
                      
                        
                        
                        <div className="card">
                            <img className="quotes" src="assets/images/quotes.svg" alt="alternative" />
                            <div className="card-body">
                                <p className="testimonial-text">Suspendisse vitae enim arcu. Aliqu convallis risus a felis blandit, at mollis nisi bibendum aliquam noto ricos</p>
                                <div className="testimonial-author">Vanya Dropper</div>
                                <div className="occupation">General Manager, Presentop</div>
                            </div>
                            <div className="gradient-floor red-to-blue"></div>
                        </div>
                      

                        
                        <div className="card">
                            <img className="quotes" src="assets/images/quotes.svg" alt="alternative" />
                            <div className="card-body">
                                <p className="testimonial-text">Suspendisse vitae enim arcu. Aliqu convallis risus a felis blandit, at mollis nisi bibendum aliquam noto ricos</p>
                                <div className="testimonial-author">Lefty Brown</div>
                                <div className="occupation">General Manager, Presentop</div>
                            </div>
                            <div className="gradient-floor blue-to-purple"></div>
                        </div>
                      

                        
                        <div className="card">
                            <img className="quotes" src="assets/images/quotes.svg" alt="alternative" />
                            <div className="card-body">
                                <p className="testimonial-text">Suspendisse vitae enim arcu. Aliqu convallis risus a felis blandit, at mollis nisi bibendum aliquam noto ricos</p>
                                <div className="testimonial-author">Susane Blake</div>
                                <div className="occupation">General Manager, Presentop</div>
                            </div>
                            <div className="gradient-floor purple-to-green"></div>
                        </div>
                      

                    </div> 
                </div>
            </div> 
        </div>  */}


        {/* <div id="features" className="accordion-1">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <h2 className="h2-heading">Online service features</h2>
                        <p className="p-heading">Suspendisse vitae enim arcu. Aliquam convallis risus a felis blandit, at mollis nisi bibendum. Aliquam nec purus at ex blandit posuere nec a odio. Proin lacinia dolor justo</p>
                    </div> 
                </div>   
                <div className="row">
                    <div className="col-xl-5">
                        <div className="accordion" id="accordionExample">
                            
                           
                            <div className="accordion-item">
                                <div className="accordion-icon">
                                    <span className="fas fa-tv"></span>
                                </div> 
                                <div className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Analyse the product and design plan
                                    </button>
                                </div>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">Mauris ornare libero et pharetra hendrerit. Cura elementum lectus quis tellus pretium, vitae lobortis dui sagittis aliquam et enim vel semon anticus</div>
                                </div>
                            </div>
                           

                           
                            <div className="accordion-item">
                                <div className="accordion-icon blue">
                                    <span className="fas fa-microphone"></span>
                                </div> 
                                <div className="accordion-header" id="headingTwo">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Find the market opportunities
                                    </button>
                                </div>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">Mauris ornare libero et pharetra hendrerit. Cura elementum lectus quis tellus pretium, vitae lobortis dui sagittis aliquam et enim vel semon anticus</div>
                                </div>
                            </div>
                           

                           
                            <div className="accordion-item">
                                <div className="accordion-icon purple">
                                    <span className="fas fa-video"></span>
                                </div> 
                                <div className="accordion-header" id="headingThree">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Prepare the product launch campaign
                                    </button>
                                </div>
                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">Mauris ornare libero et pharetra hendrerit. Cura elementum lectus quis tellus pretium, vitae lobortis dui sagittis aliquam et enim vel semon anticus</div>
                                </div>
                            </div>
                           
                            
                           
                            <div className="accordion-item">
                                <div className="accordion-icon orange">
                                    <span className="fas fa-tools"></span>
                                </div> 
                                <div className="accordion-header" id="headingFour">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        Evaluate the campaign and adjust
                                    </button>
                                </div>
                                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">Mauris ornare libero et pharetra hendrerit. Cura elementum lectus quis tellus pretium, vitae lobortis dui sagittis aliquam et enim vel semon anticus</div>
                                </div>
                            </div>
                           

                        </div> 
                    </div> 
                    <div className="col-xl-7">
                        <div className="image-container">
                            <img className="img-fluid" src="assets/images/features-dashboard.png" alt="alternative"/>
                        </div> 
                    </div> 
                </div>
            </div> 
        </div>  */}
       


       

      


{/*       
        <div className="slider-1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h4>Trusted by over <span className="blue">5000</span> customers worldwide</h4>
                        <hr className="section-divider" />

                     
                        <div className="slider-container">
                            <div className="swiper-container image-slider">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <img className="img-fluid" src="assets/images/customer-logo-1.png" alt="alternative" />
                                    </div>
                                    <div className="swiper-slide">
                                        <img className="img-fluid" src="assets/images/customer-logo-2.png" alt="alternative" />
                                    </div>
                                    <div className="swiper-slide">
                                        <img className="img-fluid" src="assets/images/customer-logo-3.png" alt="alternative" />
                                    </div>
                                    <div className="swiper-slide">
                                        <img className="img-fluid" src="assets/images/customer-logo-4.png" alt="alternative" />
                                    </div>
                                    <div className="swiper-slide">
                                        <img className="img-fluid" src="assets/images/customer-logo-5.png" alt="alternative" />
                                    </div>
                                    <div className="swiper-slide">
                                        <img className="img-fluid" src="assets/images/customer-logo-6.png" alt="alternative" />
                                    </div>
                                </div>
                            </div> 
                        </div> 
                       

                    </div> 
                </div>
            </div> 
        </div>  */}
       


{/*      
        <div className="basic-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-container">
                            <h2>Find the right influencer for your product</h2>
                            <p className="p-large">Eu convallis arcu ultrices in. Mauris ornare libero et pharetra hendrerit. Curabitur elementum lectus quis vioc tellus</p>
                            <a className="btn-solid-lg" href="#contact">Get free quote</a>
                        </div> 
                    </div> 
                </div>
            </div> 
        </div>  */}
        <div id="contact" className="form-1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="text-container">
                            {/* <div className="section-title"></div> */}
                            <h2>取得最新報價</h2>
                            <p>留下您的聯繫方式，我們會盡快聯絡你 或 直接Whatsapp 我們查詢</p>
                            <p>電話: 852-35996907</p>
                            <p>Whatsapp: 852-63373578</p>
                            <p>電郵: info@lbp.com.hk</p>
                            <p>地址: 九龍黃大仙新蒲崗大有街25-27號中興大廈B座1樓</p>
                            <p></p>
                            <span className="fa-stack">
                                <a href={this.WhatsappLink("我想取得最新報價及了解更多")}>
                                    <i className="fas fa-circle fa-stack-2x"></i>
                                    <i className="fab fa-whatsapp fa-stack-1x"></i>
                                </a>
                            </span>
                            <span className="fa-stack">
                                <a href="tel:63373578">
                                    <i className="fas fa-circle fa-stack-2x"></i>
                                    <i className="fa fa-phone fa-stack-1x"></i>
                                </a>
                            </span>
                            <span className="fa-stack">
                                <a href="mailto:info@lbp.com.hk">
                                    <i className="fas fa-circle fa-stack-2x"></i>
                                    <i className="fa fa-envelope fa-stack-1x"></i>
                                </a>
                            </span>

                            
                            {/* <ul className="list-unstyled li-space-lg">
                                <li className="d-flex">
                                    <i className="fas fa-square"></i>
                                    <div className="flex-grow-1">Vel maximus nunc aliquam ut. Donec semper, magna a pulvinar</div>
                                </li>
                                <li className="d-flex">
                                    <i className="fas fa-square"></i>
                                    <div className="flex-grow-1">Suscipit sit amet quis lorem. Sed risus ipsum, egestas mare</div>
                                </li>
                                <li className="d-flex">
                                    <i className="fas fa-square"></i>
                                    <div className="flex-grow-1">Sem pulvinar suscipit sit amet quis lorem. Sed risus</div>
                                </li>
                            </ul> */}
                        </div> 
                    </div> 
                    <div className="col-lg-6">

                       
                        <form>
                            <div className="form-group">
                                <input type="text" className="form-control-input" placeholder="姓名" required />
                            </div>
                            <div className="form-group">
                                <input type="email" className="form-control-input" placeholder="電郵" required />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control-input" placeholder="電話" required />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control-input" placeholder="你的商戶名稱" required />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="form-control-submit-button">Submit</button>
                            </div>
                        </form>
                      

                    </div> 
                </div>
            </div> 
        </div>
{/*        
        <div className="footer bg-gray">
            <img className="decoration-circles" src="assets/images/decoration-circles.png" alt="alternative" />
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h4>If you want to find out which are the right influencers for your product marketing campaigns then follow us</h4>
                        <div className="social-container">
                            <span className="fa-stack">
                                <a href="#your-link">
                                    <i className="fas fa-circle fa-stack-2x"></i>
                                    <i className="fab fa-facebook-f fa-stack-1x"></i>
                                </a>
                            </span>
                            <span className="fa-stack">
                                <a href="#your-link">
                                    <i className="fas fa-circle fa-stack-2x"></i>
                                    <i className="fab fa-twitter fa-stack-1x"></i>
                                </a>
                            </span>
                            <span className="fa-stack">
                                <a href="#your-link">
                                    <i className="fas fa-circle fa-stack-2x"></i>
                                    <i className="fab fa-pinterest-p fa-stack-1x"></i>
                                </a>
                            </span>
                            <span className="fa-stack">
                                <a href="#your-link">
                                    <i className="fas fa-circle fa-stack-2x"></i>
                                    <i className="fab fa-instagram fa-stack-1x"></i>
                                </a>
                            </span>
                            <span className="fa-stack">
                                <a href="#your-link">
                                    <i className="fas fa-circle fa-stack-2x"></i>
                                    <i className="fab fa-youtube fa-stack-1x"></i>
                                </a>
                            </span>
                            <span className="fa-stack">
                                <a href="#your-link">
                                    <i className="fas fa-circle fa-stack-2x"></i>
                                    <i className="fab fa-whatsapp fa-stack-1x"></i>
                                </a>
                            </span>
                        </div> 
                    </div> 
                </div>
            </div> 
        </div>  */}


        <div className="copyright bg-gray">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <ul className="list-unstyled li-space-lg p-small">
                            <li><a href="#">Terms & Conditions</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                        </ul>
                    </div> 
                    <div className="col-lg-6 col-md-12 col-sm-12"> 
                        <p className="p-small statement">Copyright © <a href="#">LBP Limited 科昌科技有限公司</a></p>
                    </div> 

                    {/* <div className="col-lg-3 col-md-12 col-sm-12">
                        <p className="p-small statement">Distributed by <a href="https://therichpost.com/" target="_blank">Jassa</a></p>
                    </div>  */}
                </div> 
            </div> 
        </div>
       
        <button id="myBtn">
            <img src="assets/images/up-arrow.png" alt="alternative" />
        </button>
      
    </div>
);
}
}
export default App;